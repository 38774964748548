import { useInView } from 'framer-motion';
import { useRef } from 'react';
import { ViewContainerProps } from '@atoms/ViewContainer/ViewContainer.props';

const ViewContainer = (props: ViewContainerProps) => {
  const { children, once = true, root, margin, amount } = props;

  const ref = useRef<HTMLDivElement>(null);

  const isInView = useInView(ref, {
    once,
    root,
    margin,
    amount,
  });

  return <div ref={ref}>{children(isInView)}</div>;
};

export default ViewContainer;
